import { RouteProps } from 'react-router-dom';
import { yandexRoutesConfig } from './yandexRoutes';
import { marketplacesRoutesConfig } from './marketplacesRoutes';
import { settingsRoutesConfig } from './settingsRoutes';
import { loyaltyRoutesConfig } from './loyaltyRoutes';
import { ozonRoutesConfig } from './ozonRoutes';
import { wbRoutesConfig } from './wbRoutes';

export const appRoutes: RouteProps[] = [];
appRoutes.push(...Object.values(marketplacesRoutesConfig));
appRoutes.push(...Object.values(settingsRoutesConfig));
appRoutes.push(...Object.values(loyaltyRoutesConfig));
appRoutes.push(...Object.values(ozonRoutesConfig));
appRoutes.push(...Object.values(wbRoutesConfig));
appRoutes.push(...Object.values(yandexRoutesConfig));
