export enum MenuItemType {
    Marketplaces = 1,
    Finance = 2,
    Reports = 3,
    Loyalty = 4,
    Settings = 5,
    Ozon = 6,
    Wb = 7,
    Yandex = 8,
}
