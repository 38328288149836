export enum MainRoutes {
    MARKETPLACES = '/marketplaces/',
    FINANCE = '/finance/',
    REPORTS = '/reports/',
    SETTINGS = '/settings/',
    LOYALTY = '/loyalty/',
    OZON = '/ozon/',
    WB = '/wb/',
    YANDEX = '/yandex/',
}
