import { RouteProps } from 'react-router-dom';
import { YandexPath, yandexRoutes } from 'shared/config/routeConfig/yandexRoutes';
import { YandexActionsPage } from 'pages/Yandex';

export const yandexRoutesConfig: Record<YandexPath, RouteProps> = {
  [YandexPath.ACTIONS]: {
    path: yandexRoutes[YandexPath.ACTIONS],
    element: <YandexActionsPage />,
  },
};
