import { MenuItemType } from 'entities/Menu';
import { marketplacesRoutes } from 'shared/config/routeConfig/marketplacesRouteConfig';
import { loyaltyRoutes } from 'shared/config/routeConfig/loyaltyRouteConfig';
import { settingsRoutes } from 'shared/config/routeConfig/settingsRouteConfig';
import { MainRoutes } from 'shared/config/routeConfig';
import { ozonRoutes } from 'shared/config/routeConfig/ozonRouteConfig';
import { wbRoutes } from 'shared/config/routeConfig/wbRoutes';
import { yandexRoutes } from 'shared/config/routeConfig/yandexRoutes';

export const menuItems = [
  {
    name: 'Маркетплейсы',
    type: MenuItemType.Marketplaces,
    path: MainRoutes.MARKETPLACES,
    children: [
      { path: marketplacesRoutes.dashboard, name: 'Обзор' },
      { path: marketplacesRoutes.products, name: 'Товары' },
      { path: marketplacesRoutes.orders, name: 'Заказы' },
      { path: marketplacesRoutes.returns, name: 'Возвраты' },
      { path: marketplacesRoutes.ordersManage, name: 'Сборка заказов' },
      { path: marketplacesRoutes.productLinks, name: 'Связки' },
    ],
    permittedRoles: ['admin', 'siteManager'],
  },
  {
    name: 'Ozon',
    type: MenuItemType.Ozon,
    path: MainRoutes.OZON,
    children: [
      { path: ozonRoutes.stocks, name: 'Остатки' },
      { path: ozonRoutes.operations, name: 'Начисления' },
      { path: ozonRoutes.fboPostings, name: 'Fbo продажи' },
      { path: ozonRoutes.realizationReports, name: 'Отчеты о реализации Fbo' },
      { path: ozonRoutes.salesReport, name: 'Отчет по продажам' },
      { path: ozonRoutes.actions, name: 'Акции' },
    ],
    permittedRoles: ['admin'],
  },
  {
    name: 'Wb',
    type: MenuItemType.Wb,
    path: MainRoutes.WB,
    children: [
      { path: wbRoutes.realizationReports, name: 'Отчеты о реализации' },
    ],
    permittedRoles: ['admin'],
  },
  {
    name: 'Yandex',
    type: MenuItemType.Yandex,
    path: MainRoutes.YANDEX,
    children: [
      { path: yandexRoutes.actions, name: 'Акции' },
    ],
    permittedRoles: ['admin'],
  },
  // {
  //   name: 'Финансы',
  //   type: MenuItemType.Finance,
  //   path: FINANCE_ROUTE,
  //   children: [
  //     { path: '/salary', name: 'Отчет для ЗП' },
  //   ],
  //   permitted: false,
  // },
  // {
  //   name: 'Отчеты',
  //   type: MenuItemType.Reports,
  //   path: REPORTS_ROUTE,
  //   children: [
  //     { path: '/test', name: 'Тест' },
  //   ],
  //   permitted: false,
  // },
  {
    name: 'Бонусы',
    type: MenuItemType.Loyalty,
    path: MainRoutes.LOYALTY,
    children: [
      { path: loyaltyRoutes.customers, name: 'Контрагенты' },
    ],
    permittedRoles: ['admin'],
  },
  {
    name: 'Настройки',
    type: MenuItemType.Settings,
    path: MainRoutes.SETTINGS,
    children: [
      { path: settingsRoutes.organizations, name: 'Организации' },
      { path: settingsRoutes.logs, name: 'Логи' },
      { path: settingsRoutes.eventLogs, name: 'Лог событий' },
      { path: settingsRoutes.users, name: 'Пользователи' },
      { path: settingsRoutes.moySklad, name: 'МойСклад' },
      { path: settingsRoutes.ozon, name: 'Ozon' },
      { path: settingsRoutes.yandex, name: 'Yandex' },
      { path: settingsRoutes.wildberries, name: 'Wildberries' },
      { path: settingsRoutes.megaMarket, name: 'СберМегаМаркет' },
    ],
    permittedRoles: ['admin'],
  },
];
